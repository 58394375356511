import { useDispatch, useSelector } from 'react-redux'
import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure
} from "@chakra-ui/react"

import { getSceneId } from '../redux/sceneIdSelector'
import sceneIdDuck from '../redux/sceneIdDuck'
import requestJob from '../api/requestJob'

function JobModalContainer({id}) {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const sceneId = useSelector(getSceneId)
  const dispatch = useDispatch()

  function handleClick() {
    // set scene id
    // open modal
    dispatch(sceneIdDuck.actions.setSceneId(id))
    onOpen()
  }

  async function handleProcessClick() {
    console.log(`Process ${sceneId}`)
    const result = await requestJob({ sceneId })
    console.log({result})
  }

  return (
      <>
      <Button onClick={handleClick}>Job Status</Button>

      <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
      <ModalHeader>Job Status</ModalHeader>
      <ModalCloseButton />
      <ModalBody>
      {sceneId}
      </ModalBody>

      <ModalFooter>
      <Button colorScheme="blue" mr={3} onClick={onClose}>
      Close
    </Button>
      <Button variant="ghost" onClick={handleProcessClick}>Add to queue</Button>
      <Button variant="ghost" onClick={handleProcessClick}>Remove</Button>
      </ModalFooter>
      </ModalContent>
      </Modal>
      </>
  )
}

export default JobModalContainer
