// @flow

import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  sceneId: undefined
}

function setSceneId (state, action) {
  const { payload } = action

  state.sceneId = payload
}

const sceneIdDuck = createSlice({
  name: 'sceneId',
  initialState,
  reducers: {
    setSceneId
  }
})

export default sceneIdDuck
