// @flow
import { configureStore } from '@reduxjs/toolkit'
import { combineReducers } from 'redux'
import { firebaseReducer } from 'react-redux-firebase'
import { firestoreReducer } from 'redux-firestore'

import sceneIdDuck from '../redux/sceneIdDuck'

const reducer = combineReducers({
  sceneId: sceneIdDuck.reducer,
  firebase: firebaseReducer,
  firestore: firestoreReducer
})

const store = configureStore({
  reducer: reducer,
  middleware: []
})

window.store = store

export default store
