const prefectApi = "https://api.prefect.io/"
// const prefectFlowId = "3582bb1d-e768-4941-99ce-34f47245e627"
const prefectFlowId = "55af5ce1-0a42-47ce-858d-2e94aa172b2a"
const prefectToken = "9Q7zKXOeezIb6u2wsp05Dg"
const defaultSceneId = "U1A_IW_GRDH_1SDV_20191108T000152_20191108T000217_029812_036640_BF9F"

async function requestJob({ sceneId = defaultSceneId }) {
  const createMutation = `
    mutation($input: createFlowRunInput!){
      createFlowRun(input: $input){
        flow_run{
          id
        }
      }
    }
  `
  const body = {
    query: createMutation,
    variables: {
      input: {
        flowId: prefectFlowId,
        parameters: {
          scene_id: sceneId
        }
      }
    }
  }
  console.log({ body })
  const response = await fetch(
    prefectApi,
    {
      method: "POST",
      body: JSON.stringify(body),
      headers: new Headers({
        'Authorization': `Bearer ${prefectToken}`,
        'Content-Type': 'application/json'
      }),
    }
  )
  const result = await response.json()

  return result
}

export default requestJob
