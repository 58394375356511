// Firebase
export const apiKey = process.env.REACT_APP_API_KEY
export const authDomain = process.env.REACT_APP_AUTH_DOMAIN
export const databaseURL = process.env.REACT_APP_DATABASE_URL
export const projectId = process.env.REACT_APP_PROJECT_ID
export const storageBucket = process.env.REACT_APP_STORAGE_BUCKET
export const messagingSenderId = process.env.REACT_APP_MESSAGING_SENDER_ID
export const appId = process.env.REACT_APP_APP_ID
export const firebaseConfig = {
  apiKey, authDomain, databaseURL, projectId, storageBucket, messagingSenderId
}
