import React from 'react';
import {
  ChakraProvider,
  Box,
  VStack,
  Grid,
  theme,
  Heading,
} from '@chakra-ui/react';
import { useSelector } from 'react-redux'

import { ColorModeSwitcher } from './components/ColorModeSwitcher';
import Search from './components/Search'
import JobsContainer from './containers/JobsContainer'
import { getUid } from './redux/authSelector'

function App() {
  const uid = useSelector(getUid)
  console.log({uid})
  return (
        <ChakraProvider theme={theme}>
          <Box textAlign="center" fontSize="xl">
            <Grid minH="100vh" p={3}>
              <ColorModeSwitcher justifySelf="flex-end" />
              {uid &&
               <VStack spacing={8}>
                <Heading
                  color="teal.500"
                  fontSize="2xl"
                >
                  SAR Oil Spill Detection
                </Heading>
                <Search />
                <JobsContainer />
              </VStack>
              }
            </Grid>
          </Box>
        </ChakraProvider>
  );
}

export default App;
