// @flow

import {
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption
} from '@chakra-ui/react'
import { useFirestoreConnect } from 'react-redux-firebase'
import { useSelector } from 'react-redux'

import { getJobs } from '../redux/jobsSelector'
// import ViewJobButtonContainer from '../containers/ViewJobButtonContainer'
import JobModalContainer from '../containers/JobModalContainer'

function createRow(job, onClick){
  const { id, status } = job

  return (
      <Tr key={id}>
      <Td>{id}</Td>
      <Td>{status}</Td>
      <Td><JobModalContainer id={id} /></Td>
    </Tr>
  )
}

function TableContainer () {
  useFirestoreConnect(['jobs'])
  const jobs = useSelector(getJobs)

  function handleClick(id) {

  }

  console.log({ jobs })

  return (
    <Table variant='simple'>
      <TableCaption>SAR job processing status</TableCaption>
      <Thead>
        <Tr>
          <Th>Scene ID</Th>
          <Th>Status</Th>
          <Th>View</Th>
        </Tr>
      </Thead>
      <Tbody>
      {jobs.map(job => createRow(job, handleClick))}
      </Tbody>
      <Tfoot>
        <Tr>
          <Th>Scene ID</Th>
          <Th>Status</Th>
          <Th>View</Th>
        </Tr>
      </Tfoot>
    </Table>
  )
}

export default TableContainer
