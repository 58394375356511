// @flow

import React, { type Node } from 'react'
import { Provider } from 'react-redux'

import FirebaseProvider from './redux/firebaseProvider'
import defaultStore from './redux/store'

const ProviderWrapper = (
  {
    children,
    store=defaultStore,
  }:
  {
    +children: Node,
    store?: {},
  }
) => (
  <Provider store={defaultStore}>
    <FirebaseProvider store={defaultStore}>
      { children }
    </FirebaseProvider>
  </Provider>
)

export default ProviderWrapper
